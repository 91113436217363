
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg " >
        <div class="main-card uk-animation-slide-bottom-small"  >
          <page-title></page-title>          
          <div  class="access-list uk-padding uk-text-center ">
            <iframe :src="this.commonSetting.map" style="border:0;filter:invert(80%) grayscale(30%);-webkit-filter:invert(80%) grayscale(30%);" allowfullscreen="" loading="lazy"></iframe>
          </div> 
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>

export default {
}
</script>



<style scoped lang="scss">

.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}
</style>
